@media print { 
    /* @page { margin: 0;padding: 0;} */

    body, html, #wrapper {
        height: 100%;
    }
    .new-page { 
        page-break-after: always;
     }
    /* html, body {
        height:100vh; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
      } 
     */
     
 } 
 .box-pic {
    display: flex;
    flex-wrap:wrap;

}
.box-item-pic {
    margin-top:15px;
    align-items: center;
display: flex;
flex-direction:column;
width:50%;
}